import Api, { HealthCenter, User as ParseUser, Unit, UnitType } from '@ambuliz/sabri-core';
import * as amplitude from '@amplitude/analytics-browser';
import { datadogRum } from '@datadog/browser-rum';
import { i18n } from 'common/locale';
import { toast } from 'common/toast';
import { ReactNode, createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { User, UserHealthCenter } from '.';
import { useMicrosoftAuth } from './providers/microsoft';

export type UserUnit = {
  id: string;
  name: string;
  areaIds: string[];
};

type AuthenticationContextProps = {
  isLoggedIn: boolean;
  isMicrosoftLoggedIn: boolean;
  isLoading: boolean;
  user: User;
  healthCenter: UserHealthCenter;
  unit?: UserUnit;
  units?: string[];
  unitType?: UnitType;
  navigationACL: string[];
  isReadOnly?: boolean;
  login: (username: string, password: string) => Promise<void>;
  microsoftLogin: () => Promise<void>;
  logout: () => void;
};

const getUser = async (currentUser: Parse.User | undefined) => {
  const context: {
    unit?: UserUnit;
    units: string[];
    user?: User;
    healthCenter?: UserHealthCenter;
    unitType?: UnitType;
    navigationACL: string[];
    isReadOnly?: boolean;
  } = {
    unit: undefined,
    units: [],
    user: undefined,
    healthCenter: undefined,
    unitType: undefined,
    navigationACL: [],
    isReadOnly: false,
  };

  if (currentUser) {
    const user = await new Api.Query(ParseUser).get(currentUser.id);

    const healthCenter = await new Api.Query(HealthCenter).first();
    const unit: Unit = user.get('unit');

    if (unit) {
      if (!unit.isDataAvailable()) {
        await unit.fetchWithInclude('areas');
      }
      context.unit = {
        id: unit.id,
        name: unit.name,
        areaIds: unit.areas?.map((area) => area.id) || [],
      };
      context.unitType = unit.type;
    }

    context.units = user.units?.map((unit) => unit.id) || [];

    context.user = {
      id: user.id,
      role: user.get('role'),
      name: user.username,
    };

    if (healthCenter) {
      context.healthCenter = {
        id: healthCenter.id,
        name: healthCenter.get('name'),
        autoComputeOccupanciesActivated: healthCenter.autoComputeOccupanciesActivated,
        qrCode: healthCenter.get('qrCodeKey'),
        bookedBedDelayRule: healthCenter.bookedBedDelayRule,
        location: healthCenter.location,
        computeEmergencyOccupancies: healthCenter.computeEmergencyOccupancies,
        hasPediatricService: healthCenter.hasPediatricService,
        viaTrajectoireEnabled:
          healthCenter.viaTrajectoireOptions?.isActivated && !!healthCenter.viaTrajectoireOptions.codeSih,
        chatSupportEnabled: healthCenter.chatSupportEnabled,
        patientTransportEnabled: healthCenter.patientTransportEnabled,
        thesaurusEnabled: healthCenter.thesaurusEnabled,
        thesaurusReasonEnabled: healthCenter.thesaurusReasonEnabled,
        thesaurusUnitsSuggestionsEnabled: healthCenter.thesaurusUnitsSuggestionsEnabled,
      };
    }

    if (user.isReadOnly) {
      context.isReadOnly = user.isReadOnly;
    }

    if (user.navigationACL && user.navigationACL.length > 0) {
      context.navigationACL = user.navigationACL;
    }
  }

  return context;
};

const AuthenticationContext = createContext<AuthenticationContextProps>({} as AuthenticationContextProps);

const AuthenticationContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isLoading, setLoading] = useState(true);
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [user, setUser] = useState({} as User);
  const [healthCenter, setHealthCenter] = useState<UserHealthCenter>({} as UserHealthCenter);
  const [unit, setUnit] = useState<UserUnit>();
  const [unitType, setUnitType] = useState<UnitType>();
  const [units, setUnits] = useState<string[]>([]);
  const [navigationACL, setNavigationACL] = useState<string[]>([]);
  const [isReadOnly, setIsReadOnly] = useState(false);

  const { search, pathname } = useLocation();
  const navigate = useNavigate();

  const { login: msLogin, logout: msLogout, isAuthenticated: isMicrosoftLoggedIn } = useMicrosoftAuth();

  const resetContext = () => {
    setLoading(false);
    setLoggedIn(false);
    setUser({} as User);
    setHealthCenter({} as UserHealthCenter);
    setUnit(undefined);
    setUnitType(undefined);
    setUnits([]);
  };

  const storeParseUser = useCallback(async (parseUser?: Parse.User<Parse.Attributes>) => {
    if (parseUser) {
      const { user, unit, units, healthCenter, unitType, navigationACL, isReadOnly } = await getUser(parseUser);

      setUser(user || ({} as User));
      setUnit(unit);
      setUnitType(unitType);
      setUnits(units);
      setHealthCenter(healthCenter || ({} as UserHealthCenter));
      setNavigationACL(navigationACL);
      setIsReadOnly(isReadOnly || false);

      setLoggedIn(true);
    }
  }, []);

  const login = useCallback(
    async (username: string, password: string) => {
      setLoading(true);
      try {
        const parseUser = await Api.User.logIn(username, password);
        await storeParseUser(parseUser);
      } catch (error) {
        resetContext();
        throw error;
      } finally {
        setLoading(false);
      }
    },
    [storeParseUser]
  );

  const microsoftLogin = useCallback(async () => {
    setLoading(true);
    try {
      const parseUser = await msLogin();
      await storeParseUser(parseUser);
    } catch (error) {
      resetContext();
    } finally {
      setLoading(false);
    }
  }, [msLogin, storeParseUser]);

  useEffect(() => {
    const urlParams = new URLSearchParams(search);

    const username = urlParams.get('username') || '';
    const password = urlParams.get('password') || '';

    if (!isLoggedIn && username && password) {
      login(username, password);
    }

    if (isLoggedIn && (username || password)) {
      urlParams.delete('username');
      urlParams.delete('password');
      navigate(
        {
          pathname,
          search: urlParams.toString(),
        },
        { replace: true }
      );
    }
  }, [search, pathname, isLoggedIn, login, navigate]);

  const logout = async () => {
    try {
      await Api.User.logOut();
      await msLogout(user.name);
    } catch (error) {}
    localStorage.clear();
    resetContext();
  };

  useEffect(() => {
    const initializeContext = async (currentUser: Parse.User | undefined) => {
      try {
        if (currentUser) {
          setLoading(true);
          const { user, unit, units, healthCenter, unitType, navigationACL, isReadOnly } = await getUser(currentUser);
          setUser(user || ({} as User));
          setUnit(unit);
          setUnitType(unitType);
          setUnits(units);
          setHealthCenter(healthCenter || ({} as UserHealthCenter));
          setNavigationACL(navigationACL);
          setIsReadOnly(isReadOnly || false);

          setLoggedIn(true);
        } else {
          localStorage.clear();
        }
      } catch (error) {
        resetContext();
        localStorage.clear();
      } finally {
        setLoading(false);
      }
    };

    if (!isLoggedIn) {
      initializeContext(Api.User.current());
    }
  }, [login, isLoggedIn]);

  useEffect(() => {
    const requestHeaders: { [key: string]: string | undefined } = {
      'X-Web-App-Version': process.env.REACT_APP_VERSION,
    };

    if (user.id) {
      datadogRum.setUser(user);
      requestHeaders['X-Parse-Session-Token'] = Api.User.current()?.getSessionToken();

      amplitude.setUserId(user.name);
      amplitude.setGroup('role', user.role);
      amplitude.setGroup('healthCenter', healthCenter.name);
      if (unit) {
        amplitude.setGroup('unit', unit.name);
      }
    } else {
      datadogRum.clearUser();
      amplitude.reset();
    }

    Api.CoreManager.set('REQUEST_HEADERS', requestHeaders);
  }, [user, healthCenter, unit]);

  // Global invalid session token error
  useEffect(() => {
    window.onunhandledrejection = (error: PromiseRejectionEvent) => {
      if (error.reason.code === 'INVALID_SESSION_TOKEN') {
        toast.warning(i18n.invalidSession);
        resetContext();
      }
    };
  }, []);

  return (
    <AuthenticationContext.Provider
      value={{
        user,
        isLoading,
        unit,
        units,
        healthCenter,
        unitType,
        isLoggedIn,
        isReadOnly,
        login,
        microsoftLogin,
        logout,
        navigationACL,
        isMicrosoftLoggedIn,
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
};

const useAuthentication = () => {
  const context = useContext(AuthenticationContext);
  return context;
};

export { AuthenticationContextProvider, useAuthentication };
